import React from 'react';
import { withRouter } from './shared/classhooks';
import { Link } from "react-router-dom";
import { OneElements } from './shared/elements';
import { getCntrprty } from '../api';

// // https://stackoverflow.com/a/20732091
// function humanFileSize(size) {
//     const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
//     return +((size / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
// }

class Datapage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            root_response_loading: true,
            root_response_loading_error: null,
            root_response: {},
        };
    }

    async fetchData() {

        try {
            const root_response = await getCntrprty(`/`);
            this.setState({
                root_response_loading: false,
                root_response,
            });
        }
        catch (err) {
            this.setState({
                root_response_loading_error: err,
            });
        }

    }

    async componentDidMount() {
        await this.fetchData();
    }

    render() {

        let dbsize = '';
        if (
            this.state.root_response &&
            this.state.root_response.node &&
            this.state.root_response.node.dbsize
        ) {
            dbsize = `${this.state.root_response.node.dbsize}`;
        }

        // const text_history = `All state history`;
        // const text_history = `All history`;

        const route_element = (
            <div class="py-2 my-2">
                <h2 class="font-bold text-xl mb-1">
                    Data:
                </h2>
                {/* <p class="dark:text-slate-100">
                    DB size: {humanFileSize(dbsize)}
                    // {dbsize} bytes ({humanFileSize(dbsize)})
                </p> */}
                <div class="py-1 my-1">
                    <div class="py-1 my-1">
                        <p><Link to={`/assets`}>All assets</Link></p>
                    </div>
                    <div class="py-1 my-1">
                        <p><Link to={`/messages`}>All state messages</Link></p>
                        {/* <p><Link to={`/history`}>{text_history}</Link></p> */}
                        <p><Link to={`/transactions`}>All transactions</Link></p>
                        <p><Link to={`/blocks`}>All blocks</Link></p>
                    </div>
                </div>
            </div>
        );
        // const route_element = (
        //     <div class="py-2 my-2">
        //         <h2 class="font-bold text-xl mb-1">
        //             Data:
        //         </h2>


        //         <div class="py-1 my-1">
        //             <h3 class="font-bold">
        //                 v9.61
        //             </h3>
        //             <div class="py-1 my-1">
        //                 {/* <p class="dark:text-slate-100">
        //                     All transaction and state messages in ascending order.
        //                 </p> */}
        //                 <p><Link to={`/history`}>{text_history}</Link></p>
        //                 {/* <p><Link to={`/history`}>All state history</Link></p> */}
        //                 {/* <p><Link to={`/messages`}>All transaction messages</Link></p> */}
        //                 <p><Link to={`/transactions`}>All transactions</Link></p>
        //                 {/* <p><Link to={`/blocks`}>All blocks</Link></p> */}
        //             </div>
        //         </div>

        //         <div class="py-1 my-1">
        //             <h3 class="font-bold">
        //                 v9.60
        //             </h3>
        //             <div class="py-1 my-1">
        //                 <p><Link to={`/history`}>{text_history}</Link></p>
        //                 <p><Link to={`/transactions`}>All transactions</Link></p>
        //             </div>
        //         </div>

        //         <div class="py-1 my-1">
        //             <h3 class="font-bold">
        //                 v9.59
        //             </h3>
        //             <div class="py-1 my-1">
        //                 <p><Link to={`/history`}>{text_history}</Link></p>
        //                 <p><Link to={`/transactions`}>All transactions</Link></p>
        //             </div>
        //         </div>

        //         <div class="py-1 my-1">
        //             <div class="py-1 my-1">
        //                 <p><Link to={`/blocks`}>All blocks</Link></p>
        //             </div>
        //         </div>

        //     </div>
        // );

        return <OneElements route_element={route_element} />;
        // return OneElements.getFullPageForRouteElement(page_element);

    }
}

export default withRouter(Datapage);
